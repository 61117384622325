import { APP_INITIALIZER, NgModule, Provider } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateModule } from '@ngx-translate/core';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MessageService } from 'primeng/api';
import { PanelMenuModule } from 'primeng/panelmenu';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { ConfigService } from '@core/services';
import { HttpInterceptor } from '@core/interceptors/http.interceptor';
import {
    AppTopBarComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppFooterComponent,
    AppMainComponent,
    AppConfigComponent,
    AppRightMenuComponent,
    TimeZoneContainerComponent,
} from '@core/components';
import { SubMenuPositionDirective } from '@core/directives';

export function initConfig(config: ConfigService): object {
    return () => config.load();
}

const INTERCEPTOR_PROVIDER: Provider = {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptor,
    multi: true
};

@NgModule({
    declarations: [
        AppTopBarComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppFooterComponent,
        AppMainComponent,
        AppConfigComponent,
        AppRightMenuComponent,
        SubMenuPositionDirective,
        TimeZoneContainerComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        TranslateModule,
        RadioButtonModule,
        DropdownModule,
        BreadcrumbModule,
        ButtonModule,
        RippleModule,
        InputTextModule,
        ReactiveFormsModule,
        InputSwitchModule,
        PanelMenuModule,
        MultiSelectModule,
        OverlayPanelModule,
    ],
    exports: [
        AppTopBarComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppFooterComponent,
        AppMainComponent,
        AppConfigComponent,
        AppRightMenuComponent,
        TimeZoneContainerComponent,
        RouterModule,
        FormsModule,
        TranslateModule,
        RadioButtonModule,
        DropdownModule,
        BreadcrumbModule,
        ButtonModule,
        RippleModule,
        InputTextModule,
        ReactiveFormsModule,
        InputSwitchModule,
        PanelMenuModule,
        MultiSelectModule,
        OverlayPanelModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [ConfigService],
            multi: true,
        },
        MessageService,
        INTERCEPTOR_PROVIDER,
        TitleCasePipe,

    ]
})
export class CoreModule {
}
