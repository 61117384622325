export enum RegexesEnum  {
    IgnoreCaseFlag = 'i',
    GlobalSearchFlag = 'g',
    MultilineFlag = 'm',
    UnicodeFlag = 'u',
    StickyFlag = 'y',
    DoToAllFlag = 's',

    AnyWordCharPatternRegex = '\w*',
    NamePatternRegex = '^(?=.*[A-Z]|[a-z]|[0-9])([A-Za-z0-9.\s_-]+)$',
    UrlPatternRegex = '^((([a-z]|\\d|-)*(\\.|:|\\/)+([a-z]|\\d)*)(,?))+$',
    NumberPatternRegex = '^[0-9]+$',
    PartnerGroupNamePatternRegex = '[a-zA-Z0-9+_@.-]*$',
    MobileNumberPatternRegex = '^[\+][(]?[0-9]{3}[)]?[0-9]{2,16}$'
}
