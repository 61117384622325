import { ColDef } from 'ag-grid-community';

export function generateColumnDefs(name: string, columnDefs: ColDef[]): ColDef[] {
    if ( localStorage.getItem(`column-state_${name}`) ) {
        const savedState = JSON.parse(localStorage.getItem(`column-state_${name}`));
        const newState = [];
        savedState.forEach(state => {
            const column = columnDefs.find(c => c.field === state.colId);
            if (column) {
                newState.push({ ...column, ...state, sort: null });
            }
        });
        return newState;
    }
    return columnDefs;
}
