import { UrlMatchResult, UrlSegment } from "@angular/router";

export const paymentsMatcher = (url: UrlSegment[]): UrlMatchResult | null => {
    if (url.length === 1 && (url[0].path === 'deposits' || url[0].path === 'withdrawals')) {
        return {
            consumed: url,
            posParams: {
                paymentRequestType: new UrlSegment(url[0].path, {})
            }
        };
    }
    return null;
};

export const paymentsDetailMatcher = (url: UrlSegment[]): UrlMatchResult | null => {
    if (url[0].path === 'deposits' || url[0].path === 'withdrawals') {
        return {
            consumed: url.slice(0, 2),
            posParams: {
                paymentRequestType: new UrlSegment(url[0].path, {}),
                id: new UrlSegment(url[1].path, {})
            }
        };
    }
    return null;
};