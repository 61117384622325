import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpService } from '@core/services';

import { environment } from '@env/environment';
import { CorrectionActionType, Methods } from '@core/enums';
import {
    IAccountHistory,
    IFriend,
    IFriendsReqObj,
    IKYCDocument,
    IPlayer,
    IPlayerAccountTypes,
    IPlayerCorrection,
    IPlayerCorrections,
    IPlayerDetail,
    IPlayerWallets,
    IResponseData,
    IResponseWithCount,
    ISelectItem,
    ISessionDocument,
    ISessionFilterOptions
} from '@core/interfaces';

@Injectable({
    providedIn: 'root'
})
export class PlayersService {

    public playerInfo$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public correctionAccountTypes$: BehaviorSubject<ISelectItem[]> = new BehaviorSubject<ISelectItem[]>([]);
    public KYCDocumentStates$: BehaviorSubject<ISelectItem[]> = new BehaviorSubject([]);
    public sessionDocumentTypes$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public sessionLogoutTypes$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public callsTypes$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public sendKYCCountInfo$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor(private http: HttpService) {
        this.loadKYCDocumentStatesEnumModels();
        this.getSessionStatesEnum();
        this.getLogoutTypesEnum();
        this.loadClientAccountTypes();
        this.callsTypes();
    }

    public createPlayer(player: IPlayer): Observable<any> {
        const payload = {
            Controller: 'Client',
            Method: 'RegisterClient',
            RequestObject: player
        };

        return this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false, null, true);
    }

    public getPlayerDetail(playerId: number): Observable<any> {
        const payload = {
            Controller: 'Client',
            Method: 'GetClientById',
            RequestObject: playerId
        };

        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );

    }

    public getPlayerChangeHistory(test): Observable<any> {
        const payload = {
            Controller: 'Report',
            Method: 'GetObjectChangeHistory',
            RequestObject: test
        };

        return this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false, null, true);
    }

    public changePlayerDetails(playerDetail: IPlayerDetail): Observable<any> {

        const payload = {
            Controller: 'Client',
            Method: 'ChangeClientDetails',
            RequestObject: playerDetail
        };

        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );
    }

    public changePlayerPassword(changePasswordObj): Observable<any> {

        const payload = {
            Controller: 'Client',
            Method: 'ResetClientPassword',
            RequestObject: changePasswordObj
        };

        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );
    }

    public sendPlayerEmail(sendEmailObj): Observable<any> {

        const payload = {
            Controller: 'Client',
            Method: 'SendEmailToClient',
            requestObject: sendEmailObj
        };

        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );
    }

    public getPlayerInfoById(id: number): void {
        const payload = {
            Controller: 'Client',
            Method: 'GetClientInfo',
            RequestObject: id
        };
        this.http.request('post', environment.ApiUrl + Methods.MAIN_REQUEST, payload)
            .pipe(tap(user => {
                this.playerInfo$.next(user);
            })).subscribe();
    }

    public getFriends(reqObj: IFriendsReqObj): Observable<IResponseData<IFriend[]>> {
        const payload = {
            Controller: 'Client',
            Method: 'GetAffiliateClientsOfManager',
            RequestObject: reqObj
        };

        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );
    }

    public getLogs(reqObj: any): Observable<IResponseData<any>> {
        const payload = {
            Controller: 'Client',
            Method: 'GetClientLogs',
            RequestObject: reqObj
        };

        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );
    }

    public getEmails(reqObj: any): Observable<IResponseData<any>> {
        const payload = {
            Controller: 'Client',
            Method: 'GetEmails',
            RequestObject: reqObj
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );
    }

    public getCalls(playerId: object): Observable<IResponseData<any>> {
        const payload = {
            Controller: 'Client',
            Method: 'GetCalls',
            RequestObject: playerId
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );
    }

    public callToClient(playerId: object): Observable<IResponseData<any>> {
        const payload = {
            Controller: 'Client',
            Method: 'CallToClient',
            RequestObject: playerId
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );
    }


    public callsTypes(): void {
        if (localStorage.getItem('enum_calls-types')) {
            this.callsTypes$.next(JSON.parse(localStorage.getItem('enum_calls-types')));
        } else {
            const typeData = [
                { Id: 1, Name: 'New', Background: '#E3E3E8', Color: '#B3B3BC' },
                { Id: 2, Name: 'Voip', Background: '#E3E3E8', Color: '#B3B3BC' }
            ];
            localStorage.setItem('enum_calls-types', JSON.stringify(typeData));
            this.callsTypes$.next(JSON.parse(localStorage.getItem('enum_calls-types')));
        }
    }

    public changeCallInfo(reqObj): Observable<IResponseData<any>> {
        const payload = {
            Controller: 'Client',
            Method: 'ChangeCallInfo',
            RequestObject: reqObj
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST,
            payload
        );
    }


    public getPlayerCorrections(reqObj: any): Observable<IResponseWithCount<IPlayerCorrections>> {
        const payload = {
            Controller: 'Client',
            Method: 'GetClientCorrections',
            RequestObject: reqObj
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );
    }

    public makePlayerCorrections(reqObj: any): Observable<IResponseData<IPlayerCorrection>> {
        const payload = {
            Controller: 'Client',
            Method: reqObj.Type === CorrectionActionType.Debit ? 'CreateDebitCorrection' : 'CreateCreditCorrection',
            RequestObject: reqObj,
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload
        );
    }

    public getAccountHistory(reqObj: any): Observable<IResponseData<IAccountHistory[]>> {
        const payload = {
            Controller: 'Client',
            Method: 'GetClientAccountsBalanceHistoryPaging',
            RequestObject: reqObj
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );
    }

    public getKYCDocuments(playerId: number): Observable<IResponseData<IKYCDocument[]>> {
        const payload = {
            Controller: 'Client',
            Method: 'GetClientIdentityModel',
            RequestObject: playerId
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );
    }

    public getSessionDocument(filterOptions: ISessionFilterOptions): Observable<IResponseWithCount<ISessionDocument>> {
        const payload = {
            Controller: 'Client',
            Method: 'GetClientLoginsPagedModel',
            RequestObject: filterOptions
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );
    }

    public getSessionStatesEnum(): void {
        if (localStorage.getItem('enum_session-states')) {
            this.sessionDocumentTypes$.next(JSON.parse(localStorage.getItem('enum_session-states')));
        } else {
            const sessionStates = [
                { Name: 'Active', Id: 1, Background: '#79F2B8', Color: '#3f3f3f' },
                { Name: 'Inactive', Id: 2, Background: '#E3E3E8', Color: '#B3B3BC' },
                { Name: 'Pending', Id: 3, Background: '#FEEDAF' },
                { Name: 'Wrong Password', Id: 32, Background: '#EE6464', Color: '#3f3f3f' },
                { Name: 'AML Prohibited', Id: 155, Background: '#EE6464', Color: '#3f3f3f' },
                { Name: 'JCJ Excluded', Id: 164, Background: '#EE6464', Color: '#3f3f3f' }
            ];
            this.sessionDocumentTypes$.next(sessionStates);
        }
    }

    public getLogoutTypesEnum(): void {
        if (localStorage.getItem('enum_logout-type')) {
            this.sessionLogoutTypes$.next(JSON.parse(localStorage.getItem('enum_logout-type')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetLogoutTypesEnum',
                RequestObject: {}
            };

            this.http.request('post',
                environment.ApiUrl + Methods.MAIN_REQUEST,
                payload,
                false,
                null,
                true
            )
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        data.ResponseObject.map(status => {
                            status.Background = status.Name === 'Approved' ? '#79F2B8' :
                                (status.Name === 'Expired' || status.Name === 'Rejected') ? '#EE6464' :
                                    (status.Name === 'In Process') ? '#E3E3E8' : '#E3E3E8';

                            status.Color = status.Name === 'Approved' ? '#3F3F3F' :
                                (status.Name === 'Expired' || status.Name === 'Rejected') ? '#3F3F3F' :
                                    (status.Name === 'In Process') ? '#B3B3BC' : '#B3B3BC';
                        });
                        localStorage.setItem('enum_logout-type', JSON.stringify(data.ResponseObject));
                        this.sessionLogoutTypes$.next(JSON.parse(localStorage.getItem('enum_logout-type')));
                    }
                });
        }
    }

    public removeKYCDocument(id: number): Observable<IResponseData<any>> {
        const payload = {
            Controller: 'Client',
            Method: 'RemoveClientIdentity',
            RequestObject: id
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );
    }

    public createKYCDocument(reqObj: any): Observable<IResponseData<any>> {
        const payload = {
            Controller: 'Client',
            Method: 'UploadImage',
            RequestObject: reqObj
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );
    }

    public updateKYCDocument(reqObj: any): Observable<IResponseData<any>> {
        const payload = {
            Controller: 'Client',
            Method: 'UpdateClientIdentityModel',
            RequestObject: reqObj
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );
    }

    public loadKYCDocumentStatesEnumModels(): void {
        if (localStorage.getItem('enum_KYC-document-states')) {
            this.KYCDocumentStates$.next(JSON.parse(localStorage.getItem('enum_KYC-document-states')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetKYCDocumentStatesEnum',
                RequestObject: {}
            };

            this.http.request(
                'post',
                environment.ApiUrl + Methods.MAIN_REQUEST, payload,
                false,
                null,
                true)
                .subscribe((res) => {
                    if (res?.ResponseCode === 0 && res.ResponseObject) {
                        res.ResponseObject.map(status => {
                            status.Background = status.Name === 'Approved' ? '#79F2B8' :
                                (status.Name === 'Expired' || status.Name === 'Rejected') ? '#EE6464' :
                                    (status.Name === 'In Process') ? '#E3E3E8' : '#E3E3E8';

                            status.Color = status.Name === 'Approved' ? '#3F3F3F' :
                                (status.Name === 'Expired' || status.Name === 'Rejected') ? '#3F3F3F' :
                                    (status.Name === 'In Process') ? '#B3B3BC' : '#B3B3BC';
                        });
                        localStorage.setItem('enum_KYC-document-states', JSON.stringify(res.ResponseObject));
                        this.KYCDocumentStates$.next(res.ResponseObject);
                    }
                });
        }
    }

    public getPlayerWallets(playerId: number): Observable<IResponseData<IPlayerWallets[]>> {
        const payload = {
            Controller: 'Client',
            Method: 'GetClientWallets',
            RequestObject: playerId
        };
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );
    }

    public loadClientAccountTypes(): void {
        if (localStorage.getItem('enum_client-account-types')) {
            this.correctionAccountTypes$.next(JSON.parse(localStorage.getItem('enum_client-account-types')));
        } else {
            const payload = {
                Controller: 'EnumerationModel',
                Method: 'GetClientAccountTypesEnum',
                RequestObject: {}
            };

            this.http.request(
                'post',
                environment.ApiUrl + Methods.MAIN_REQUEST, payload,
                false,
                null,
                true)
                .subscribe(data => {
                    if (data.ResponseCode === 0 && data.ResponseObject) {
                        localStorage.setItem('enum_client-account-types', JSON.stringify(data.ResponseObject));
                        this.correctionAccountTypes$.next(data.ResponseObject);
                    }
                });
        }
    }

    public getPlayerAccountTypesByCurrency(reqObj: any): Observable<IResponseData<IPlayerAccountTypes[]>> {
        const payload = {
            Controller: 'Client',
            Method: 'GetClientAccountTypesByCurrency',
            RequestObject: reqObj
        };

        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MAIN_REQUEST, payload,
            false,
            null,
            true
        );
    }
}
