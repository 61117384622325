export enum SEOEnumKeys {
    SyncTypes = 'SeoSyncType',
    RedirectTypes = 'SeoRedirectType',
    TextFileTypes = 'SeoTextFileType',
}

export enum SEOTextFileTypeEnum {
    RobotsTxt = 1,
    SecurityTxt = 2,
}

export enum SEOSitemapModalTypeEnum {
    Edit = 0,
    AddManually = 1,
    AddViaUpload = 2,
}

export enum SEOSyncTypeEnum {
    Robots = 1,
    Security = 2,
    Sitemap = 3,
    Redirect = 4,
}
