export enum NoteTypeEnum {
    Player = 2,
    Bets = 12,
    Payments = 15,
    KYC = 57,
    Calls = 85,
    BlackList = 88,
    Partner = 89,
    PartnerApiUrl = 90
}
