export enum Methods {
    MAIN_REQUEST = 'Main/ApiRequest',
    LOGIN_USER = 'Main/LoginUser',
    CHANGE_PASSWORD = 'Main/ChangePassword',
    CHANGE_LANGUAGE = 'Main/ChangeLanguage',
    PARTNER_ADMIN_CONFIG = 'Main/GetPartnerAdminConfig',
    UPDATE_BET_SETTINGS = 'Main/CreateWinOrLostOrRollbackDocumentFromFile',
    HEALTH_CHECK = 'HealthCheck',
    GET_TRANSLATIONS = 'Translation/get-translations-by-parent-id',
    GET_LANGUAGES = 'translation/get-languages',
    ADD_LANGUAGE = 'translation/add-language',
    UPDATE_LANGUAGE = 'translation/update-language',
    REMOVE_LANGUAGE = 'translation/remove-language',
    GET_LANGUAGE_STATES = 'translation/get-language-states',
    CHANGE_LANGUAGE_STATUS = 'translation/change-language-status',
    GET_PARTNER_LANGUAGE_SETTINGS = 'translation/get-partner-language-settings',
    GET_LANGUAGES_BY_PARTNER_ID_AND_STATE = 'translation/get-languages-by-partnerid-and-state',
    CHANGE_PARTNER_LANGUAGE_STATUS = 'translation/change-partner-language-status',
    ADD_TRANSLATION = 'translation/add',
    UPDATE_TRANSLATION_JSON = 'translation/upload-json-files',
    EDIT_TRANSLATION = 'translation/update-translation',
    REMOVE_TRANSLATION = 'translation/remove-translation',
    GET_PROJECT_TYPES = 'translation/get-project-types',
    REQUEST_WITH_IMAGE_FILE = 'Main/ApiRequestWithImageFile',
    BANNER_PARTNER_BANNERS = 'banner/partner-banners',
    BANNER_GET_ALL = 'banner/get-all',
    BANNER_STATUSES = 'banner/banner-statuses',
    BANNER_TYPES = 'banner/banner-types',
    BANNER_VISIBILITY = 'banner/banner-visibility',
    BANNER_DEVICE_TYPES = 'banner/device-types',
    BANNER_MODE = 'banner/mode',
    BANNER_AVAILABLE_PAGE_TYPES = 'banner/available-page-types',
    ADD_BANNER = 'banner/add',
    UPDATE_BANNER = 'banner/update',
    UPDATE_BANNER_STATUS = 'banner/change-banner-status',
    UPDATE_BANNER_ORDER = 'banner/change-banner-order',
    DELETE_BANNER = 'banner/delete',
    BUTTONS_STRUCTURE = 'banner/buttons-structure',
    BUTTONS_POSITIONS = 'banner/buttons-positions',
    BLACK_LIST = 'BlackList',
    GET_BLACKLIST_TYPES_ENUM = 'BlackList/GetBlackListTypesEnum',
    GET_BLACKLIST = 'BlackList/GetBlackList',
    ADD_TO_BLACKLIST = 'BlackList/CreateBlackListItem',
    EDIT_BLACKLIST_ITEM = 'BlackList/UpdateBlackListItem',
    UNBLOCK_BLACKLIST_ITEM = 'BlackList/DeleteBlackListItem',
    FILE_UPLOAD = 'file/upload',
    GET_PROMOTIONS_MENU = 'PromotionMenu/get-grouped',
    ADD_PROMOTIONS_MENU = 'PromotionMenu/add',
    UPDATE_PROMOTIONS_MENU = 'PromotionMenu/update',
    UPDATE_PROMOTIONS_MENU_STATUS = 'PromotionMenu/update-status',
    DELETE_PROMOTION_MENU = 'PromotionMenu/remove',
    GET_PROMOTIONS = 'Promotion/get-grouped',
    ADD_PROMOTION = 'Promotion/add',
    UPDATE_PROMOTION = 'Promotion/update',
    UPDATE_PROMOTION_STATUS = 'Promotion/update-status',
    DELETE_PROMOTION = 'Promotion/remove',
    GET_CMS_ENUM = 'enum/',
    GET_PAGES = 'routepage/get-all/',
    DELETE_PAGE = 'routepage/delete/',
    CHANGE_PAGE_STATUS = 'routepage/change-status',
    COPY_PAGE_FROM_PARTNER = 'routepage/copy-from-partner',
    CREATE_PAGE = 'routepage/add',
    GET_PARTNER_DOMAIN = 'PartnerDomain/GetPartnerDomains',
    CREATE_PARTNER_DOMAIN = 'PartnerDomain/CreatePartnerDomain',
    UPDATE_PARTNER_DOMAIN = 'PartnerDomain/UpdatePartnerDomain',
    DELETE_PARTNER_DOMAIN = 'PartnerDomain/DeletePartnerDomain',
    GET_PARTNER_DOMAIN_TYPES = 'PartnerDomain/GetPartnerDomainTypes',
    GET_PARTNER_DOMAIN_SEGMENTS = 'PartnerDomain/GetPartnerDomainSegments',
    ASSETS_GET_All = 'assets/get-all',
    ADD_FILE = 'assets/add-file',
    ADD_FOLDER = 'assets/add-folder',
    UPDATE_FOLDER = 'assets/update-folder',
    DELETE_FOLDER = 'assets/remove-folder',
    UPDATE_FILE = 'assets/update-file',
    DELETE_FILE = 'assets/remove-file',
    COPY_FROM_PARTNER = 'assets/copy-from-partner',
    GET_BLOG_MENUS = 'blogmenu/getall',
    ADD_BLOG_MENU = 'blogmenu/add',
    UPDATE_BLOG_MENU = 'blogmenu/update',
    UPDATE_BLOG_MENU_STATUS = 'blogmenu/update-status',
    DELETE_BLOG_MENU = 'blogmenu/remove',
    GET_BLOGS = 'blog/get-grouped',
    ADD_BLOG = 'blog/add',
    UPDATE_BLOG = 'blog/update',
    UPDATE_BLOG_STATUS = 'blog/update-status',
    DELETE_BLOG = 'blog/remove',
    GET_PARTNER_TRANSLATION_KEYS = 'translation/get-parent-translation-keys',
    COPY_TRANSLATIONS_FROM_PARTNER = 'translation/copy-from-partner',
    GET_PENDING_COPY_PARTNER_TRANSLATIONS = 'translation/get-pending-copy-partner-translations',
    GET_TRANSLATION_SUB_KEYS = 'common/get-translation-sub-keys-by-parent',
    GET_REDIRECTS = 'seo/get-all-redirects',
    ADD_REDIRECT = 'seo/add-redirect',
    UPDATE_REDIRECT = 'seo/update-redirect',
    SEO_SYNC_FILE = 'seo/sync-file',
    DELETE_REDIRECT = 'seo/removeredirect',
    GET_SITEMAPS = 'seo/get-all-sitemaps',
    GET_SITEMAP = 'seo/getsitemap',
    ADD_SITEMAP = 'seo/add-sitemap',
    UPDATE_SITEMAP = 'seo/update-sitemap',
    DELETE_SITEMAP = 'seo/remove-sitemap',
    GET_TEXT_FILE = 'seo/gettextfile',
    UPDATE_TEXT_FILE = 'seo/update-textfile',
}
