import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpEventType,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';

import { AuthService, ToastService } from '@core/services';

@Injectable()
export class HttpInterceptor implements HttpInterceptor {

    private static modifyRequest(
        req: HttpRequest<any>,
        UserId?: string | null,
        Token?: string | null
    ): HttpRequest<any> {

        if (!req.url.includes('.json') && !(req.body instanceof FormData)) {

            const tableFilters = req?.body?.FilterModel;
            if (tableFilters) {
                if ( Object.keys(tableFilters).length !== 0 ) {
                    const newFilters = [];
                    Object.keys(tableFilters).forEach((key: string) => {
                        newFilters.push({...tableFilters[key], fieldName: key});
                    });
                    req.body.FilterModel = newFilters;
                } else {
                    req.body.FilterModel = [];
                }
            }

            return req.clone({
                    body: {
                        ...req.body,
                        Token,
                    },
                    setHeaders: { Token, UserId  }
                },
            );

        }
        if (req.body instanceof FormData) {
            const formData: FormData = req.body;
            formData.append('Token', Token);
        }
        if (!req.url.includes('.json')) {
            return req.clone({
                setHeaders: { Token, UserId }
            });
        }


        return req;
    }

    constructor(
        private authService: AuthService,
        private router: Router,
        private toastService: ToastService
    ) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = HttpInterceptor.modifyRequest(
            req,
            this.authService.userData$.getValue() ? this.authService.userData$.getValue().UserId.toString() : '',
            req.body?.Token ? req.body.Token : this.authService.tokenGetter() ? this.authService.tokenGetter() : '',
        );

        return next.handle(req)
            .pipe(
                tap({
                    next: (event): void => {
                        if (event.type === HttpEventType.Response) {

                        const maintenance = event.headers.get('admin-underconstraction');
                        if ( maintenance && !event.url?.includes('.json') ) {
                            this.router.navigate(['/under-maintenance']);
                        } else if ( this.router.url === '/under-maintenance' && !maintenance && !event.url?.includes('.json')) {
                            this.router.navigate(['/']);
                        }

                        // need to be implemented
                        if (event.body && event.body?.ResponseCode === 28 || event.body?.ResponseCode === 29) {
                            if (!!this.authService.tokenGetter()) {
                                this.authService.logout();
                            } else {
                                this.router.navigate(['/login']);
                            }
                        }
                        }
                    }
                }),
                catchError(err => {
                    if (err instanceof HttpErrorResponse)  {
                        if (err.status === 401) {
                            this.authService.logout();
                            this.toastService.showToastMsg('error', err.error?.message);
                        }
                        if (err.status === 0) {
                            console.log('Check Your Internet Connection And Try again Later');
                        }
                    }
                    return throwError(err);
                })
            );

    }
}
