import {
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AppMainComponent } from '@core/components';
import { MenuService } from '@core/services';
import { IMenuItem } from '@core/interfaces';

@Component({
    /* tslint:disable:component-selector */
    selector: '[app-menuitem]',
    templateUrl: './app.menuitem.component.html',
    // tslint:disable-next-line:no-host-metadata-property
    host: {
        '[class.layout-root-menuitem]': 'root',
        '[class.active-menuitem]': '(active && !root) || (active && (appMain.isSlim() || appMain.isHorizontal()))'
    },
    animations: [
        trigger('children', [
            state('void', style({
                height: '0px'
            })),
            state('hiddenAnimated', style({
                height: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            state('visible', style({
                height: '*',
                'z-index': 100
            })),
            state('hidden', style({
                height: '0px',
                'z-index': '*'
            })),
            state('slimVisibleAnimated', style({
                opacity: 1,
                transform: 'none'
            })),
            state('slimHiddenAnimated', style({
                opacity: 0,
                transform: 'translateX(20px)'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('void => visibleAnimated, visibleAnimated => void',
                animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('void => slimVisibleAnimated', animate('400ms cubic-bezier(.05,.74,.2,.99)')),
            transition('slimHiddenAnimated => slimVisibleAnimated', animate('400ms cubic-bezier(.05,.74,.2,.99)'))
        ])
    ]
})
export class AppMenuitemComponent implements OnInit, OnDestroy {

    @Input() item: IMenuItem;

    @Input() index: number;

    @Input() root: boolean;

    @Input() parentKey: string;

    active = false;

    key: string;

    slimClick = false;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        public appMain: AppMainComponent,
        public router: Router,
        private cd: ChangeDetectorRef,
        private menuService: MenuService) {
        this.menuService.menuSource$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(key => {
                // deactivate current active menu
                if (this.appMain.staticMenuDesktopInactive
                    && this.appMain.isStatic()
                    && this.active
                    && this.key !== key
                    && key.indexOf(this.key) !== 0) {
                    this.active = false;
                }
            });

        this.menuService.resetSource$
            .pipe(takeUntil(this.unsubscribe$))
                .subscribe(() => {
                this.active = false;
            });

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((): void => {
                if (this.appMain.isSlim() || this.appMain.isHorizontal()) {
                    this.active = false;
                } else {
                    if (this.item.routerLink) {

                        setTimeout((): void => {
                            const activeRoute: Element = document.querySelector('.active-route');
                            const layoutMenu: Element = document.querySelector('.layout-menu');
                            const elementsWithHref: NodeListOf<Element> = layoutMenu.querySelectorAll('[href]');
                            if (activeRoute) {
                                elementsWithHref.forEach((element: Element): void => {
                                    if (!this.router.url.includes(element.getAttribute('href'))) {
                                        element.classList.remove('active-route');
                                        element.parentElement.classList.remove('active-menuitem');
                                        return;
                                    } else if (element.getAttribute('href') !== '/'
                                                && element.getAttribute('href').split('/')[1]
                                                    === this.router.url.split('/')[1]
                                    ) {
                                        element.parentElement.classList.add('active-menuitem');
                                    }
                                });
                            } else {
                                elementsWithHref.forEach((element: Element): void => {
                                    if (element.getAttribute('href') !== '/'
                                        && this.router.url !== element.getAttribute('href')
                                        && this.router.url.includes(element.getAttribute('href'))
                                    ) {
                                        element.classList.add('active-route');
                                        element.parentElement.classList.add('active-menuitem');
                                    }
                                });
                            }
                            this.updateActiveStateFromRoute();
                        });
                    } else if (this.appMain.staticMenuDesktopInactive
                        && this.appMain.isStatic()) {
                        this.active = false;
                    }
                }
            });
    }

    static isTablet(): boolean {
        return [
            'Android',
            'webOS',
            'iPad',
            'iPad Simulator',
        ].includes(navigator.platform);
    }

    ngOnInit(): void {
        if (!(this.appMain.isSlim() || this.appMain.isHorizontal()) && this.item.routerLink) {
            this.updateActiveStateFromRoute();
        } else if (!(this.appMain.staticMenuDesktopInactive
            && this.appMain.isStatic())) {
            this.toggleActiveMenuIfNeeded();
        }

        this.key = this.parentKey ? this.parentKey + '-' + this.index : String(this.index);
    }

    ngOnDestroy(): void {
       this.unsubscribe$.next();
       this.unsubscribe$.complete();
    }

    private toggleActiveMenuIfNeeded(): void {
        setTimeout((): void => {
            const layoutMenu: Element = document.querySelector('.layout-menu');
            const elementsWithHref: NodeListOf<Element> = layoutMenu.querySelectorAll('[href]');

            const elementsArray: Element[] = Array.from(elementsWithHref);

            for (const element of elementsArray) {
                if (element.getAttribute('href') !== '/'
                    && this.router.url !== element.getAttribute('href')
                    && element.getAttribute('href').split('/')[1] === this.router.url.split('/')[1]
                    && this.router.url.includes(element.getAttribute('href'))
                ) {
                    element.classList.add('active-route');
                    element.parentElement.classList.add('active-menuitem');
                    break;
                }
            }

            const activeRouteElem: Element = document.querySelector('.active-route');

            if (this.item.level && activeRouteElem) {
                const activeUlElem: HTMLElement = activeRouteElem.parentElement.parentElement;
                const activeListItemElem: HTMLElement = activeUlElem.parentElement;

                if (!activeListItemElem.classList.contains('layout-root-menuitem')) {
                    activeListItemElem.classList.add('active-menuitem');
                    activeUlElem.style.height = '';
                }
            }
        });
    }

    updateActiveStateFromRoute(): void {
        this.active = this.router.isActive(this.item.routerLink[0], !this.item.items && !this.item.preventExact);
    }

    secondaryItemClick(event: Event): void {
        event.stopPropagation();
        return;
    }

    itemClick(event): void {
        if (this.appMain.isSlim()) {
            this.slimClick = true;
        }

        // avoid processing disabled items
        if (this.item.disabled) {
            event.preventDefault();
            return;
        }

        // navigate with hover in horizontal mode
        if (this.root) {
            this.appMain.menuHoverActive = !this.appMain.menuHoverActive;
        }

        if (this.item.items && this.appMain.staticMenuDesktopInactive
            && this.appMain.isStatic()) {
            this.appMain.menuHoverActive = !this.appMain.menuHoverActive;
        }

        // notify other items
        this.menuService.onMenuStateChange(this.key);

        // execute command
        if (this.item.command) {
            this.item.command({originalEvent: event, item: this.item});
        }

        // toggle active state
        if (this.item.items) {
            if (!(this.appMain.staticMenuDesktopInactive && this.appMain.isStatic()) &&
                !this.active && event.target.parentNode.classList.contains('active-menuitem')) {
                this.active = true;
                setTimeout(() => this.active = false);
            } else {
                this.active = !this.active;
            }
        } else {
            // activate item
            this.active = true;

            if (this.appMain.isMobile()) {
                this.appMain.staticMenuMobileActive = false;
            }

            // reset horizontal menu
            if ((this.appMain.staticMenuDesktopInactive
                && this.appMain.isStatic()) || this.appMain.isSlim() || this.appMain.isHorizontal()) {
                this.menuService.reset();
                this.appMain.menuHoverActive = false;
            }

            this.appMain.unblockBodyScroll();
        }

        this.removeActiveInk(event);
    }

    onMouseEnter(): void {
        // activate item on hover
        if (this.root && (this.appMain.isSlim() || this.appMain.isHorizontal()) && this.appMain.isDesktop()) {
            if (this.appMain.menuHoverActive) {
                this.menuService.onMenuStateChange(this.key);
                this.active = true;
                this.slimClick = false;
            } else {
                if (this.appMain.isSlim()) {
                    this.slimClick = true;
                }
            }
        }

        if (this.appMain.staticMenuDesktopInactive
            && this.appMain.isStatic()
            && this.appMain.isDesktop()
            && !AppMenuitemComponent.isTablet()) {
            this.menuService.onMenuStateChange(this.key);
            this.active = true;
        }
    }

    removeActiveInk(event: Event): void {
        const currentTarget = (event.currentTarget as HTMLElement);
        setTimeout(() => {
            if (currentTarget) {
                const activeInk = currentTarget.querySelector('.p-ink-active');
                if (activeInk) {
                    if (activeInk.classList) {
                        activeInk.classList.remove('p-ink-active');
                    } else {
                        activeInk.className = activeInk.className
                            .replace(new RegExp('(^|\\b)' + 'p-ink-active'
                            .split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
                    }
                }
            }
        }, 401);
    }
}
