<div class="layout-sidebar-right" [ngClass]="{'layout-sidebar-right-active': appMain.rightMenuActive}"
     (click)="appMain.rightMenuClick = true">
    <div class="quick-search-form">
        <form [formGroup]="quickSearchForm">
            <div formGroupName="Player">
                <h5 class="mb-1">{{ 'Player' | translate }}</h5>
                <hr class="mb-4 mt-2"/>
                <div class="formgroup-inline flex-nowrap">
                    <div class="field">
                        <input pInputText
                               type="text"
                               formControlName="PlayerId"
                               [placeholder]="'PlayerId' | translate"
                               (keydown)="onInputKeyDown('Player', 'PlayerId', 'players', $event)"
                               class="quick-search-input"/>
                    </div>
                    <button pButton
                            class="no-label button-go"
                            (click)="onButtonClick('Player', 'PlayerId', 'players', $event)">
                        <span class="pi icon-go icon-size-sm"></span>
                    </button>
                </div>

                <div class="formgroup-inline flex-nowrap">
                    <div class="field">
                        <input pInputText
                               type="text"
                               formControlName="Username"
                               [placeholder]="'Username' | translate"
                               (keydown)="onInputKeyDown('Player', 'Username', 'players', $event)"
                               class="quick-search-input"/>
                    </div>
                    <button pButton
                            class="no-label button-go"
                            (click)="onButtonClick('Player', 'Username', 'players', $event)">
                        <span class="pi icon-go icon-size-sm"></span>
                    </button>
                </div>

                <div class="formgroup-inline flex-nowrap">
                    <div class="field">
                        <input pInputText
                               type="text"
                               formControlName="Email"
                               [placeholder]="'Email' | translate"
                               (keydown)="onInputKeyDown('Player', 'Email', 'players', $event)"
                               class="quick-search-input"/>
                        <small *ngIf="quickSearchForm.get(['Player' , 'Email']).touched
                                      && quickSearchForm.get(['Player' , 'Email']).hasError('email')"
                               class="p-error field-error-message">{{ 'FieldIsNotValidMessage' | translate }}</small>
                    </div>
                    <button pButton
                            class="no-label button-go"
                            (click)="onButtonClick('Player', 'Email', 'players', $event)">
                        <span class="pi icon-go icon-size-sm"></span>
                    </button>
                </div>

                <div class="formgroup-inline flex-nowrap">
                    <div class="field">
                        <input pInputText
                               type="text"
                               formControlName="MobileNumber"
                               [placeholder]="'MobileNumber' | translate"
                               (keydown)="onInputKeyDown('Player', 'MobileNumber', 'players', $event)"
                               class="quick-search-input"/>
                        <small *ngIf="quickSearchForm.get(['Player' , 'MobileNumber']).touched
                                      && quickSearchForm.get(['Player' , 'MobileNumber']).hasError('pattern')"
                               class="p-error field-error-message">{{ 'FieldIsNotValidMessage' | translate }}</small>
                    </div>
                    <button pButton
                            class="no-label button-go"
                            (click)="onButtonClick('Player', 'MobileNumber', 'players', $event)">
                        <span class="pi icon-go icon-size-sm"></span>
                    </button>
                </div>

                <div class="formgroup-inline flex-nowrap">
                    <div class="field">
                        <input pInputText
                               type="text"
                               formControlName="RegistrationIp"
                               [placeholder]="'IpRegistration' | translate"
                               (keydown)="onInputKeyDown('Player', 'RegistrationIp', 'players', $event)"
                               class="quick-search-input"/>
                    </div>
                    <button pButton
                            class="no-label button-go"
                            (click)="onButtonClick('Player', 'RegistrationIp', 'players', $event)">
                        <span class="pi icon-go icon-size-sm"></span>
                    </button>
                </div>

                <div class="formgroup-inline flex-nowrap">
                    <div class="field">
                        <input pInputText
                               type="text"
                               formControlName="DocumentNumber"
                               [placeholder]="'DocumentID' | translate"
                               (keydown)="onInputKeyDown('Player', 'DocumentNumber', 'players', $event)"
                               class="quick-search-input"/>
                    </div>
                    <button pButton
                            class="no-label button-go"
                            (click)="onButtonClick('Player', 'DocumentNumber', 'players', $event)">
                        <span class="pi icon-go icon-size-sm"></span>
                    </button>
                </div>
            </div>

            <div formGroupName="Document">
                <h5 class="mb-1">{{ 'Document' | translate }}</h5>
                <hr class="mb-4 mt-2"/>

                <div class="formgroup-inline flex-nowrap">
                    <div class="field">
                        <input pInputText
                               type="text"
                               formControlName="DepositId"
                               [placeholder]="'DepositId' | translate"
                               (keydown)="onInputKeyDown('Document', 'DepositId', 'payments/deposits', $event)"
                               class="quick-search-input"/>
                    </div>
                    <button pButton
                            class="no-label button-go"
                            (click)="onButtonClick('Document', 'DepositId', 'payments/deposits', $event)">
                        <span class="pi icon-go icon-size-sm"></span>
                    </button>
                </div>

                <div class="formgroup-inline flex-nowrap">
                    <div class="field">
                        <input pInputText
                               type="text"
                               formControlName="WithdrawalId"
                               [placeholder]="'WithdrawalId' | translate"
                               (keydown)="onInputKeyDown('Document', 'WithdrawalId', 'payments/withdrawal', $event)"
                               class="quick-search-input"/>
                    </div>
                    <button pButton
                            class="no-label button-go"
                            (click)="onButtonClick('Document', 'WithdrawalId', 'payments/withdrawal', $event)">
                        <span class="pi icon-go icon-size-sm"></span>
                    </button>
                </div>

                <div class="formgroup-inline flex-nowrap">
                    <div class="field">
                        <input pInputText
                               type="text"
                               formControlName="InternetBetId"
                               [placeholder]="'InternetBetId' | translate"
                               (keydown)="onInputKeyDown('Document', 'InternetBetId', 'bets', $event)"
                               class="quick-search-input"/>
                    </div>
                    <button pButton
                            class="no-label button-go"
                            (click)="onButtonClick('Document', 'InternetBetId', 'bets', $event)">
                        <span class="pi icon-go icon-size-sm"></span>
                    </button>
                </div>

                <div class="formgroup-inline flex-nowrap">
                    <div class="field">
                        <input pInputText
                               type="text"
                               formControlName="ExternalId"
                               [placeholder]="'BetExternalId' | translate"
                               (keydown)="onInputKeyDown('Document', 'ExternalId', 'bets', $event)"
                               class="quick-search-input"/>
                    </div>
                    <button pButton
                            class="no-label button-go"
                            (click)="onButtonClick('Document', 'ExternalId', 'bets', $event)">
                        <span class="pi icon-go icon-size-sm"></span>
                    </button>
                </div>
            </div>
        </form>
    </div>

</div>
