import { Component, OnDestroy } from '@angular/core';
import { EMPTY, Subject, timer } from 'rxjs';
import { filter, mergeMap, switchMap, takeUntil } from 'rxjs/operators';

import { MenuItem } from 'primeng/api';

import {
    AuthService,
    BreadcrumbService,
    CommonService,
    ConfigService,
    LanguageService,
    ToastService,
    TranslationsService
} from '@core/services';
import { AppMainComponent } from '@core/components';
import { IAppConfig, ILanguage, IPartner, ITranslationsCopyData } from '@core/interfaces';
import { dataFormatter } from '@app/core/utilities';

@Component({
    selector: 'app-topbar',
    styleUrls: ['./app.topbar.component.scss'],
    templateUrl: './app.topbar.component.html'
})

export class AppTopBarComponent implements OnDestroy {

    public breadcrumbItems: MenuItem[];
    public selectedLang: { [key: string]: string };
    public config: IAppConfig;
    public darkModeActive: boolean = false;
    public panelMenuItems: MenuItem[];

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        public breadcrumbService: BreadcrumbService,
        public configService: ConfigService,
        public appMain: AppMainComponent,
        public languageService: LanguageService,
        public authService: AuthService,
        private commonService: CommonService,
        private toastService: ToastService,
        private translationsService: TranslationsService,
    ) {
        this.generateBreadcrumb();
        this.config = this.configService.config;
        this.darkModeActive = this.configService.colorScheme === 'dim';
        this.getLanguages();
        this.checkTranslationsCopyState();
    }

    ngOnDestroy(): void {
        sessionStorage.removeItem('pending-copy-translations');
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private setLanguageItems(): void {
        const items = [
            {
                label: this.selectedLang.name,
                icon: 'pi icon-translations-table icon-size-sm',
                command: (e) => e.originalEvent.stopPropagation(),
                items: this.languageService.langList$.getValue(),
                styleClass: 'language-section'
            },
        ];

        this.fixupItems(items);
        this.panelMenuItems = [...items];
    }

    private fixupItems(items): void {
        items?.forEach((item) => {
            item.command = (e) => {
                e.originalEvent.stopPropagation();
                if (!e.item.items) {
                    this.changeLanguage(e.item.value.id);
                    this.panelMenuItems[0].expanded = false;
                }
            };
            this.fixupItems(item.items);
        });
    }

    public changeLanguage(lang: string): void {
        const payload = {
            UserId: this.authService.userData$.getValue().UserId,
            LanguageId: lang,
        };

        this.languageService.onSelectLanguage(payload)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(res => {
                if (res?.ResponseCode === 0) {
                    this.languageService.setLanguage(lang);
                    this.appMain.onTopbarUserMenuButtonClick();
                    this.generateLanguageDropdown();
                    this.setLanguageItems();
                } else {
                    this.toastService.showToastMsg('error', res.Description);
                }
            });
    }

    private generateBreadcrumb(): void {
        this.breadcrumbService.breadcrumbItems$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(response => {
                this.breadcrumbItems = response;
            });
    }

    private generateLanguageDropdown(): void {
        let language = null;
        const languages = this.languageService.langList$.getValue();

        if (localStorage.getItem('lang')) {
            language = languages.find(lang => lang.value.id === localStorage.getItem('lang'))?.value;
        }

        this.selectedLang = language ? language : languages.find(lang => lang.value.id === 'en')?.value;
    }

    private getLanguages(): void {
        this.languageService.langList$
            .pipe(
                filter(data => !!data.length),
                takeUntil(this.unsubscribe$),
            )
            .subscribe({
                next: (languages: ILanguage[]) => {
                    let isLangExisted: boolean = false;
                    const storageLang = localStorage.getItem('lang');

                    languages.forEach((item => {
                        if (item.value.id === storageLang) {
                            isLangExisted = true;
                        }
                    }));
                    this.generateLanguageDropdown();

                    if (storageLang && !isLangExisted) {
                        this.languageService.setLanguage(this.selectedLang.id);
                    } else if (!storageLang) {
                        this.languageService.setLanguage('en');
                    }

                    this.setLanguageItems();
                }
            });
        this.checkTranslations();
    }

    private checkTranslations(): void {
        this.languageService.translationError$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: value => {
                    if (value) {
                        this.changeLanguage('en');
                    }
                }
            });
    }

    private checkTranslationsCopyState(): void {
        this.commonService.partners$
            .pipe(
                switchMap((partners: IPartner[]) => partners.length
                    ? timer(0, 30000)
                    : EMPTY),
                mergeMap(() => sessionStorage.getItem('pending-copy-translations')
                    ? this.translationsService.getPendingCopyPartnerTranslations()
                    : EMPTY
                ),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(this.controlTranslationsUpdate.bind(this));
    }

    private controlTranslationsUpdate([oldList, newList]: ITranslationsCopyData[][]): void {
        if (newList.length < oldList.length) {
            this.translationsService.updatedTranslationsTrigger.emit();
            oldList
                .filter((oldItem: ITranslationsCopyData) => !newList.some(newItem => oldItem.PartnerId === newItem.PartnerId))
                .forEach((diff: ITranslationsCopyData) => {
                    const diffData = JSON.parse(diff.Data);
                    this.toastService.showToastMsg(
                        'success',
                        'TranslationsCopySuccessMessage',
                        {
                            from: dataFormatter(this.commonService.partners$, { value: diffData.FromPartnerId }),
                            to: dataFormatter(this.commonService.partners$, { value: diffData.ToPartnerId })
                        }
                    );
                });
        }
        if (!newList.length) {
            sessionStorage.removeItem('pending-copy-translations');
        }
    }
}
