import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from '@core/components';

import { AppNotfoundComponent } from '@pages/notfound/app.notfound.component';
import { canActivateChildAuthGuard, canActivateUnAuthGuard } from '@core/guards';
import { AppMaintenanceComponent } from '@pages/maintenance';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                canActivateChild: [canActivateChildAuthGuard],
                children: [
                    {
                        path: '',
                        loadChildren: () => import('@pages/dashboard/dashboard.module').then(m => m.DashboardModule)
                    },
                    {
                        path: 'welcome',
                        loadChildren: () => import('@pages/welcome/welcome.module').then(m => m.WelcomeModule)
                    },
                    {
                        path: 'players',
                        loadChildren: () => import('@pages/players/players.module').then(m => m.PlayersModule)
                    },
                    {
                        path: 'payments',
                        loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule)
                    },
                    {
                        path: 'bets',
                        loadChildren: () => import('./pages/bets/bets.module').then(m => m.BetsModule)
                    },
                    {
                        path: 'loyalty-program',
                        loadChildren: () => import('./pages/loyalty-program/loyalty-program.module').then(m => m.LoyaltyProgramModule)
                    },
                    {
                        path: 'partners',
                        loadChildren: () => import('./pages/partners/partners.module').then(m => m.PartnersModule)
                    },
                    {
                        path: 'users',
                        loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule)
                    },
                    {
                        path: 'risk-management',
                        loadChildren: () => import('./pages/risk-management/risk-management.module').then(m => m.RiskManagementModule)
                    },
                    {
                        path: 'reports',
                        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule)
                    },
                    {
                        path: 'notifications',
                        loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsModule)
                    },
                    {
                        path: 'providers',
                        loadChildren: () => import('./pages/providers/providers.module').then(m => m.ProvidersModule)
                    },
                    {
                        path: 'products',
                        loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule)
                    },
                    {
                        path: 'product-groups',
                        loadChildren: () => import('./pages/product-groups/product-groups.module').then(m => m.ProductGroupModule)
                    },
                    {
                        path: 'profile',
                        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule)
                    },
                    {
                        path: 'currencies',
                        loadChildren: () => import('./pages/currencies/currencies.module').then(m => m.CurrenciesModule)
                    },
                    {
                        path: 'kyc-documents',
                        loadChildren: () => import('./pages/kyc-documents/kyc-documents.module').then(m => m.KycDocumentsModule)
                    },
                    {
                        path: 'cms',
                        loadChildren: () => import('./pages/cms/cms.module').then(m => m.CmsModule)
                    },
                ]
            },
            { path: 'notfound', component: AppNotfoundComponent },
            { path: 'under-maintenance', component: AppMaintenanceComponent },

            {
                path: 'login',
                canActivate: [canActivateUnAuthGuard],
                loadChildren: () => import('@pages/auth/auth.module').then(m => m.AuthModule)
            },


            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
