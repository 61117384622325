export enum CMSEnumKeys {
    ActionTypes = 'ActionTypes',
    BannerTypes = 'BannerTypes',
    BannerVisibility = 'BannerVisibility',
    ButtonsPositions = 'ButtonsPositions',
    DeviceTypes = 'DeviceTypes',
    LanguageStates = 'LanguageStates',
    Mode = 'Mode',
    PageTypes = 'PageTypes',
    ProjectTypes = 'ProjectTypes',
    Statuses = 'Statuses'
}

export enum CMSStorageEnumKeys {
    ActionTypes = 'action-types',
    BannerTypes = 'banner-types',
    BannerVisibility = 'banner-visibility',
    ButtonsPositions = 'buttons-positions',
    DeviceTypes = 'device-types',
    LanguageStates = 'language-states',
    Mode = 'mode',
    PageTypes = 'page-types',
    ProjectTypes = 'project-types',
    Statuses = 'statuses'
}
